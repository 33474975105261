import React, { Component } from 'react';

import Main from './components/main';

class App extends Component {
  
  render() { 
    return (
      <div className="App">                       
        <Main />             
      </div>  
        
    );
  }
}
 
export default App;



// 2C3333
// 2E4F4F
// 0E8388
// CBE4DE
